import React, { createContext, FC, useContext, useMemo } from 'react'
import { v4 as uuid_v4 } from 'uuid'

import { useProfileQuery } from '@/features/auth/queries/useProfileQuery'
import { useRoleQuery } from '@/features/auth/queries/useRoleQuery'
import { IProfile } from '@/features/auth/types'
import qs from 'qs'

export interface IAuthContextState {
  isSigned: boolean
  signInUrl: string
  profile: IProfile | null
  role: string
}

const AuthContext = createContext<IAuthContextState>({
  isSigned: false,
  signInUrl: '',
  profile: null,
  role: '',
})

export interface IAuthProviderProps {
  children: any
}

const AuthProvider: FC<IAuthProviderProps> = ({ children }) => {
  const { data: profile } = useProfileQuery()
  const { data: role } = useRoleQuery()

  const myRole = useMemo(() => {
    if (role && role.length > 0) {
      return role.find((v: string) => v === 'manager' || v === 'tutor') ?? 'trainee'
    }
    return 'trainee'
  }, [role])
  const signInUrlWithNonce = useMemo(() => {
    const params = qs.stringify({
      response_type: 'code',
      client_id: process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID,
      redirect_uri: process.env.NEXT_PUBLIC_OAUTH_REDIRECT_URL,
      scope: process.env.NEXT_PUBLIC_OAUTH_SCOPE,
    })
    const signInUrl = `${process.env.NEXT_PUBLIC_OAUTH_AUTHORIZATION_ENDPOINT}?${params}`
    return `${signInUrl}&nonce=${uuid_v4()}`
  }, [])

  return (
    <AuthContext.Provider
      value={{ isSigned: profile !== null, signInUrl: signInUrlWithNonce, profile, role: myRole }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
export const useAuth = () => useContext(AuthContext)
