import axios from 'axios'
import { ThunkAction } from 'redux-thunk'

import createAsyncThunk from '@/shared/utils/createAsyncThunk'
import { RootState } from '../index'
import {
  changePassword,
  checkPassword,
  fetchProfile,
  fetchRole,
  fetchTermsOfService,
  updateProfile,
  updateTermsOfService,
} from './actions'
import {
  requestChangePassword,
  requestCheckPassword,
  requestFetchTermsOfService,
  requestProfile,
  requestRole,
  requestUpdateProfile,
  requestUpdateTermsOfService,
} from './api'
import { IAuthAction, ITermsOfServiceUpdateParams } from './types'

export const fetchProfileThunk = createAsyncThunk(fetchProfile, requestProfile)
export const updateProfileThunk = createAsyncThunk(updateProfile, requestUpdateProfile)

export const fetchRoleThunk = createAsyncThunk(fetchRole, requestRole)

export const checkPasswordThunk = createAsyncThunk(checkPassword, requestCheckPassword)
export const changePasswordThunk = createAsyncThunk(changePassword, requestChangePassword)
export const fetchTermsOfServiceThunk = createAsyncThunk(
  fetchTermsOfService,
  requestFetchTermsOfService,
)

export function updateTermsOfServiceThunk(
  data: ITermsOfServiceUpdateParams,
): ThunkAction<Promise<void>, RootState, null, IAuthAction> {
  return async dispatch => {
    const { request, success, failure } = updateTermsOfService
    dispatch(request(undefined))
    try {
      await requestUpdateTermsOfService(data)
      dispatch(success(undefined))
    } catch (e) {
      if (axios.isAxiosError(e)) dispatch(failure(e))
    }
    await dispatch(fetchTermsOfServiceThunk())
  }
}
