import createAsyncThunk from '@/shared/utils/createAsyncThunk'
import {
  fetchCourseDetail,
  fetchCourseFAQList,
  fetchCourseSections,
  fetchKDTBasicKlassList,
  fetchKDTKlassAttr,
  fetchKDTKlassProgressStatList,
  fetchProgressList,
  fetchProgressNow,
  fetchRecommendCourseList,
} from './actions'
import {
  requestCourseDetail,
  requestCourseFAQList,
  requestCourseSections,
  requestKDTBasicKlassList,
  requestKDTKlassAttr,
  requestKDTProgressStatList,
  requestProgressList,
  requestProgressNow,
  requestRecommendCourseList,
} from './api'

export const fetchKDTKlassAttrThunk = createAsyncThunk(fetchKDTKlassAttr, requestKDTKlassAttr)

export const fetchKDTBasicKlassListThunk = createAsyncThunk(
  fetchKDTBasicKlassList,
  requestKDTBasicKlassList,
)
export const fetchCourseDetailThunk = createAsyncThunk(fetchCourseDetail, requestCourseDetail)

export const fetchCourseSectionsThunk = createAsyncThunk(fetchCourseSections, requestCourseSections)

export const fetchProgressListThunk = createAsyncThunk(fetchProgressList, requestProgressList)

export const fetchKDTKlassProgressStatListThunk = createAsyncThunk(
  fetchKDTKlassProgressStatList,
  requestKDTProgressStatList,
)

export const fetchProgressNowThunk = createAsyncThunk(fetchProgressNow, requestProgressNow)

export const fetchRecommendCourseListThunk = createAsyncThunk(
  fetchRecommendCourseList,
  requestRecommendCourseList,
)

export const fetchCourseFAQListThunk = createAsyncThunk(fetchCourseFAQList, requestCourseFAQList)
